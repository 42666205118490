<template>
  <div>
    <filter-agent />
    <agents></agents>
  </div>
</template>

<script>
const FilterAgent = () => import('@/components/search-agent/partials/filter');
const Agents = () => import('@/components/search-agent/partials/agents');
import { mapState } from 'vuex';

export default {
  components: {
    FilterAgent,
    Agents,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.global.isLoading,
      agents: (state) => state.v2.search.agents,
    }),
    isProjectEmpty() {
      return !this.isLoading && this.projects && this.projects.length < 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.wrapper-item--project {
  min-height: 100vh; // Dikasih minimum height biar scrollbar gak hilang/muncul rapidly.
}
.search--title {
  font-size: $font-md;
  color: $color-beliruma-1;
}
</style>
